<template>
    <el-dialog
        v-model="show"
        title="新增职位"
        width="700px"
    >
        <div class="user-role-add">
            <div class="basic-info">
                <el-form label-position="left" label-width="100px">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="所属部门：">
                                <el-select
                                    v-model="postForm.departmentId"
                                    placeholder="请选择所属部门"
                                >
                                    <el-option
                                        v-for="(item,index) in departmentList"
                                        :key="index"
                                        :label="item.title"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="职位名称：">
                                <el-input v-model="postForm.title" placeholder="请输入职位名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="职位标识：">
                                <el-input v-model="postForm.name" placeholder="请输入职位标识"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="权限设置：">
                                <el-checkbox-group v-model="postForm.permissionId">
                                    <el-checkbox
                                        v-for="(item,index) in permissionList"
                                        :key="index"
                                        :label="item.title"
                                    >
                                    </el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
        <template #footer>
            <el-button @click="show = false">取消</el-button>
            <el-button type="primary" @click="addRole()">新增</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {onMounted, reactive, toRefs, watch} from "vue";
import {ElMessage} from "element-plus";
import {ADD_ROLES, GET_DEPARTMENTS_LIST, GET_PERMISSIONS_LIST} from "@/api/dataProvider";

export default {
    name: "UserRoleDetail",
    props: {
        show: Boolean,
    },
    emits: ["updateList", "update:show"],
    setup(props, context) {
        let state = reactive({
            departmentList: [],
            permissionList: [],
            postForm: {
                title: "",
                name: "",
                departmentId: "",
                permissionId: []
            },
        })


        onMounted(() => {
            getDepartmentList()
            getPermissionList()
        })

        watch(() => props.show, (val) => {
            context.emit("update:show", val);
        })

        // 获取部门列表
        let getDepartmentList = () => {
            GET_DEPARTMENTS_LIST({}, (res) => {
                if (res.data.status === 200) {
                    state.departmentList = res.data.data
                }
            })
        }

        // 获取权限列表
        let getPermissionList = () => {
            GET_PERMISSIONS_LIST({}, function (res) {
                if (res.data.status === 200) {
                    state.permissionList = res.data.data
                }
            })
        }

        // 新增
        let addRole = () => {
            let permissionId = []
            state.permissionList.map((item) => {
                if (state.postForm.permissionId.indexOf(item.title) !== -1) {
                    permissionId.push(item.id)
                }
            })
            ADD_ROLES(
                {
                    ...state.postForm,
                    permissionId: permissionId
                },
                (res) => {
                    if (res.data.status === 200) {
                        ElMessage({
                            message: "新增成功!",
                            type: "success",
                        });
                        updateList()
                        context.emit("update:show", false);
                    } else {
                        ElMessage({
                            message: "新增失败，请检查后重试！",
                            type: "error"
                        });
                    }
                }
            )
        }

        // 更新父级列表
        let updateList = () => {
            context.emit("updateList");
        }

        return {
            ...toRefs(state),
            getDepartmentList,
            getPermissionList,
            addRole,
            updateList
        }
    },
}
</script>

<style lang="scss" scoped>
.user-role-add {
    .el-form {
        .el-form-item {
            .el-select {
                width: 100%;
            }
        }
    }
}
</style>
