<template>
    <div class="user-role-list">
        <div class="search-toolbar">
            <div class="tool-item">
                <span class="item-label">所属部门：</span>
                <el-select v-model="searchForm.department" class="item-select" placeholder="请选择">
                    <el-option
                        v-for="(item,index) in departmentList"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="tool-item">
                <span class="item-label">职位名称：</span>
                <el-input v-model="searchForm.title" class="item-input" placeholder="请输入查询条件"></el-input>
            </div>
            <div class="tool-item tool-buttons">
                <el-button type="primary" @click="getRoleList()">搜索</el-button>
                <el-button @click="clearSearchForm()">重置</el-button>
            </div>
        </div>
        <div v-loading="roleListLoading" class="table-container">
            <div class="buttons-wrapper">
                <el-button type="primary" @click="openAddDialog()">新建</el-button>
                <el-button type="danger" @click="openDelDialog()">删除</el-button>
            </div>
            <div class="table-wrapper">
                <el-table
                    :cell-style="{textAlign:'center'}"
                    :data="roleList"
                    :header-cell-style="{background:'#409EFF',color:'#fff',textAlign:'center'}"
                    @selection-change="getSelection"
                >
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        label="职位名称"
                        prop="title"
                    >
                    </el-table-column>
                    <el-table-column
                        label="职位标识"
                        prop="name"
                    >
                    </el-table-column>
                    <el-table-column
                        label="所属部门"
                    >
                        <template #default="scope">
                            <span v-if="scope.row.department">{{ scope.row.department.title }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="权限"
                    >
                        <template #default="scope">
                            <span v-for="(item,index) in scope.row.permissions">
                                {{ item.title }}{{ index + 1 != scope.row.permissions.length ? ";" : "" }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="100"
                    >
                        <template #default="scope">
                            <el-button size="mini" @click="openDetailDialog(scope.row.id)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination-wrapper">
                <el-pagination
                    :current-page="page.page"
                    :page-size="page.size"
                    :total="roleTotal"
                    background
                    layout="sizes,prev, pager, next,jumper"
                    @current-change="changePageCurrent"
                    @size-change="changePageSize"
                >
                </el-pagination>
            </div>
        </div>
    </div>
    <!--查看/编辑弹窗-->
    <UserRoleDetail :id="detailDialog.id" v-model:show="detailDialog.show" @updateList="getRoleList"></UserRoleDetail>
    <!--新增弹窗-->
    <UserRoleAdd v-model:show="addDialog.show" @updateList="getRoleList"></UserRoleAdd>
</template>

<script>
import {onMounted, reactive, toRefs} from "vue";
import {GET_DEPARTMENTS_LIST, GET_ROLES_LIST} from "@/api/dataProvider";
import {ElMessage, ElMessageBox} from "element-plus";
import UserRoleDetail from "./UserRoleDetail";
import UserRoleAdd from "./UserRoleAdd";
import {DEL_ROLES_DETAIL} from "../../../api/dataProvider";

export default {
    name: "UserRoleList",
    components: {
        UserRoleDetail,
        UserRoleAdd
    },
    setup() {
        let state = reactive({
            roleListLoading: false,
            // 搜索条件
            searchForm: {
                department: "",
                title: ""
            },
            // 部门列表
            departmentList: [],
            // 角色列表
            roleList: [],
            listSelection: [],
            // 分页信息
            roleTotal: 0,
            page: {
                page: 1,
                size: 10,
            },
            // 查看/编辑弹窗
            detailDialog: {
                show: false,
                id: ""
            },
            // 新增弹窗
            addDialog: {
                show: false,
            }
        })

        onMounted(() => {
            getDepartmentList()
            getRoleList()
        })

        // 获取部门列表
        let getDepartmentList = () => {
            GET_DEPARTMENTS_LIST({}, (res) => {
                if (res.data.status === 200) {
                    state.departmentList = res.data.data
                    console.log(res.data.data)
                } else {
                    ElMessage({
                        message: "列表获取失败，请刷新重试！",
                        type: "error"
                    });
                }
            })
        }

        // 获取职位列表
        let getRoleList = () => {
            state.roleListLoading = true
            GET_ROLES_LIST(
                {
                    ...state.searchForm,
                    ...state.page
                },
                (res) => {
                    if (res.data.status === 200) {
                        state.roleTotal = res.data.page.total
                        state.roleList = res.data.data
                        console.log(res.data.data)
                    } else {
                        ElMessage({
                            message: "列表获取失败，请刷新重试！",
                            type: "error"
                        });
                    }
                    state.roleListLoading = false
                })
        }

        // 修改分页当前页
        let changePageCurrent = (page) => {
            state.page.page = page
            getRoleList()
        }

        // 修改分页size
        let changePageSize = (size) => {
            state.page.size = size
            getRoleList()
        }

        // 重置搜索
        let clearSearchForm = () => {
            state.searchForm = {
                department: "",
                title: ""
            }
        }

        // 前往详情
        let openDetailDialog = (id) => {
            state.detailDialog.id = id
            state.detailDialog.show = true
        }

        // 打开新增弹窗
        let openAddDialog = () => {
            state.addDialog.show = true
        }

        // 获取列表选中id列表
        let getSelection = (arr) => {
            let idArr = []
            arr.map((item) => {
                idArr.push(item.id)
            })
            state.listSelection = idArr

        }

        // 打开删除弹窗
        let openDelDialog = (item) => {
            ElMessageBox.confirm("此操作将永久删除选中职位，是否继续？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                DEL_ROLES_DETAIL(
                    {
                        id: state.listSelection
                    },
                    (res) => {
                        if(res.data.status===200){
                            ElMessage({
                                message: "删除成功",
                                type: "success",
                            });
                            getRoleList()
                        }else{
                            ElMessage({
                                message: "删除失败",
                                type: "error",
                            });
                        }
                        console.log(res)
                    }
                )
                console.log(state.listSelection)
            })
        }

        return {
            ...toRefs(state),
            getDepartmentList,
            getRoleList,
            changePageCurrent,
            changePageSize,
            clearSearchForm,
            openDetailDialog,
            openAddDialog,
            getSelection,
            openDelDialog
        }
    },
}
</script>

<style lang="scss" scoped>

</style>