<template>
    <el-dialog
        v-model="show"
        title="职位详情"
        width="700px"
    >
        <div v-loading="loading" class="user-role-detail">
            <el-tabs>
                <el-tab-pane label="基本信息">
                    <div class="basic-info">
                        <el-form label-position="left" label-width="100px">
                            <el-row :gutter="20">
                                <el-col v-if="roleDetail.department" :span="12">
                                    <el-form-item label="所属部门：">
                                        <el-select
                                            v-model="roleDetail.department.id"
                                            placeholder="请选择所属部门"
                                        >
                                            <el-option
                                                v-for="(item,index) in departmentList"
                                                :key="index"
                                                :label="item.title"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="职位名称：">
                                        <el-input v-model="roleDetail.title" placeholder="请输入职位名称"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="职位标识：">
                                        <el-input v-model="roleDetail.name" placeholder="请输入职位标识"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="权限设置：">
                                        <el-checkbox-group v-model="permissionSelect">
                                            <el-checkbox
                                                v-for="(item,index) in permissionList"
                                                :key="index"
                                                :label="item.title"
                                            >
                                            </el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="辅助信息">
                    <div class="other-info">
                        <el-form>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="职位ID：">{{ roleDetail.id }}</el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="更新时间：">{{ roleDetail.updatedAt }}</el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="创建时间：">{{ roleDetail.createdAt }}</el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <template v-if="!loading" #footer>
            <el-button @click="show = false">取消</el-button>
            <el-button type="primary" @click="edit">修改</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {onMounted, reactive, toRefs, watch} from "vue";
import {GET_DEPARTMENTS_LIST, GET_PERMISSIONS_LIST, GET_ROLES_DETAIL} from "@/api/dataProvider";
import {ElMessage} from "element-plus";
import {UPDATE_ROLES_DETAIL} from "../../../api/dataProvider";

export default {
    name: "UserRoleDetail",
    props: {
        show: Boolean,
        id: "",
    },
    emits: ["updateList", "update:show"],
    setup(props, context) {
        let state = reactive({
            loading: false,
            departmentList: [],
            permissionList: [],
            permissionSelect: [],
            roleDetail: {}
        })

        onMounted(() => {
            if (props.id) {
                init()
            }
        })

        watch(() => props.id, (val) => {
            if (val) {
                init()
            }
        })

        watch(() => props.show, (val) => {
            context.emit("update:show", val);
        })

        // 数据操作
        let init = () => {
            state.loading = true
            getDepartmentList().then((res) => {
                return getPermissionList()
            }).then((res) => {
                return getRoleDetail()
            }).then((res) => {
                state.loading = false
            })
        }

        // 获取部门列表
        let getDepartmentList = () => {
            return new Promise((resolve) => {
                GET_DEPARTMENTS_LIST({}, (res) => {
                    if (res.data.status === 200) {
                        state.departmentList = res.data.data
                    }
                    resolve()
                })
            })
        }

        // 获取权限列表
        let getPermissionList = () => {
            return new Promise((resolve) => {
                GET_PERMISSIONS_LIST({}, function (res) {
                    if (res.data.status === 200) {
                        state.permissionList = res.data.data
                    }
                    resolve()
                })
            })
        }

        // 获取角色详情
        let getRoleDetail = () => {
            return new Promise((resolve) => {
                state.permissionSelect = []
                GET_ROLES_DETAIL(props.id, (res) => {
                    if (res.data.status === 200) {
                        state.roleDetail = res.data.data
                        state.roleDetail.permissions.map((item) => {
                            state.permissionSelect.push(item.title)
                        })
                    } else {
                        ElMessage({
                            message: "数据获取失败，请刷新重试！",
                            type: "error"
                        });
                    }
                    resolve()
                })
            })
        }

        // 编辑
        let edit = () => {
            UPDATE_ROLES_DETAIL(
                {
                    ...state.roleDetail
                },
                (res) => {
                    if (res.data.status === 200) {
                        ElMessage({
                            type: "success",
                            message: "修改成功!"
                        });
                    } else {
                        ElMessage({
                            type: "error",
                            message: "修改失败，请重试！"
                        });
                    }
                    updateList()
                }
            )
        }

        // 更新父级列表
        let updateList = () => {
            context.emit("update:show", false)
            context.emit("updateList");
        }

        return {
            ...toRefs(state),
            init,
            getDepartmentList,
            getPermissionList,
            getRoleDetail,
            edit,
            updateList
        }
    },
}
</script>

<style lang="scss" scoped>
.user-role-detail {
    .el-form {
        .el-form-item {
            .el-select {
                width: 100%;
            }
        }
    }
}
</style>